@import "variables";
// global scroll bar styles supporting only webkit engines
::-webkit-scrollbar
{
  width: 12px;
  height: 12px;
  background-color: rgba(196, 196, 196, 0.3);
  padding-right: 2px;
  padding-left: 2px;
}

::-webkit-scrollbar-track
{
  margin: 0.2em;
  border-radius: 10px;
  background-color: rgba(var(--surface-400), 0.3);
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  background-color: var(--primary-color);
}

