@import "variables";
@import "mixins";
//@import 'bootstrap/scss/bootstrap';
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/type";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/input-group";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/utilities";

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: 0.25em;
}

.gap-2 {
  gap: 0.5em;
}

.gap-3 {
  gap: 1em;
}

.gap-4 {
  gap: 1.5em;
}

.gap-5{
  gap: 3em;
}

.flex-1 {
  flex: 1 1 0;
}

.font-size-0 {
  font-size: 0.8rem;
  span {
    font-size:  inherit;
  }
}

.font-size-1 {
  font-size: 1rem;
  span:not(.p-badge) {
    font-size:  inherit;
  }
}

.font-size-2 {
  font-size: 1.5rem;
  span:not(.p-badge) {
    font-size:  inherit;
  }
}

.font-size-3 {
  font-size: 2rem;
  span:not(.p-badge) {
    font-size:  inherit;
  }
}


.list-group-item {
  background: none;
  padding: 0;
  border: 0;
}

@media (min-width: 1900px) {
  .d-xxl-block {
    display: block !important;
  }
}
