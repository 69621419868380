@import 'variables';

@mixin interaction-disabled {
  user-select: none;
  pointer-events: none;
}

@mixin custom-button($background, $color, $borderColor: $background) {
  color: $color;
  background: $background;
  border-color: $borderColor;
  box-shadow: none;

  &:disabled,.disabled, .p-disabled {
    color: $color;
    opacity: 0.6;
    cursor: not-allowed;
    > * {
      pointer-events: none;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:enabled:hover,
  &:enabled:focus,
  &:enabled:active,
  &:hover {
    color: $color;
    border-color: $borderColor;
    background: $background;
  }
}

@mixin surface-style($color, $background) {
  background: $color;
  color: $background;

  &-border {
    border-color: $color;
  }

  &-invert {
    background: $background;
    color: $color;
  }

  &-text {
    color: $color;
  }
}
