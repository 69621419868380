.table-fixed {
  table-layout: fixed;
}

.border-radius-1 {
  border-radius: var(--border-radius);
}

.border-radius-2 {
  border-radius: calc(var(--border-radius) * 2);
}

.pointer-events-none {
  pointer-events: none;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  max-width: 35px;
  max-height: 35px;
  margin: 0 auto;

  .fa-layers {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

.circle-sm {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  max-width: 30px;
  max-height: 30px;
  margin: 0 auto;

  .fa-layers {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.border-style {
  border: 1px solid var(--surface-border);
}
