/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import "mixins";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
$flag-icon-css-path: 'flag-icon-css/flags' !default;
@import "flag-icon-css/sass/flag-icons";
@import "scrollbar";
@import "bootstrap";
@import "primeng/resources/primeng";
@import "primeicons/primeicons";
//@import "primeng/resources/themes/viva-light/theme";
//@import "primeng/resources/themes/saga-blue/theme";
//@import "primeng/resources/themes/bootstrap4-light-blue/theme.css";

//@import "primeng/resources/themes/aura-light-blue/theme.css";
//@import "primeng/resources/themes/aura-dark-noir/theme.css";
@import "utils";



:root {

  --square-button-px: 36px;
  --sidebar-width-px: 250px;
  --border-width-px: 1px;
  font-variation-settings: normal;
  --font-family: 'Roboto', sans-serif;

  --content-padding: 0.5rem;
  --inline-spacing: 0.25rem;
  --border-radius: 6px;

  --focus-ring: none;
  --maskbg: rgba(0, 0, 0, 0.4);
  --transition-duration: 0.2s;

  --primary-50: #f1f5f9;
  --primary-100: #bbdefb;
  --primary-200: #8abef6;
  --primary-300: #5f9df0;
  --primary-400: #3f87ed;
  --primary-500: #009afd;
  --primary-600: #0069d9;
  --primary-700: #0050b3;
  --primary-800: #003a8f;
  --primary-900: #00215a;

  accent-color: var(--primary-color);

}



@media screen and (min-width: 1030px) {
  :root {
    --sidebar-width-px: 300px;
  }
}

@media screen and (min-width: 1920px) {
  :root {
    --sidebar-width-px: 350px;
  }
}


*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 14px;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(#000, 0);
}

body {
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: var(--content-padding);
  line-height: 1.5;
  background: var(--surface-ground);
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

p {
  margin: 0;
  padding: 0;

  &:not(.p-element) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

iframe {
  border: none;
  height: 100%;
}

a:not(.p-element) {
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  margin-bottom: 2rem;
}

table {
  border-collapse: collapse; // Prevent double borders
}

button:focus:not(:focus-visible) {
  outline: 0;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"], // 1
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; // 2
}

textarea {
  overflow: auto; // Remove the default vertical scrollbar in IE.
  // Textareas should really only resize vertically so they don't break their (horizontal) containers.
  resize: vertical;
}

//end - from reboot scss

h1, h2, h3, h4, h5, h6 {
  color: inherit;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.3rem;
}

h5 {
  font-size: 1.2rem;
}

h6 {
  font-size: 1rem;
}

h1, h2, h3, h4, h5, h6, img, button, .button, input[type="button"], input[type="submit"] {
  user-select: none;
}

label {
  user-select: none;
  display: inline-block;
  margin-bottom: .5rem;
}

.navbar {
  height: $navbar-height;
  padding: 0;
}

.engagementMainContainer {
  overflow: hidden;
  height: 100%;
}

small {
  font-size: 0.7em;
}


// global login, reset/change password styles
.login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  padding: 2rem;

  .p-inputtext {
    height: 44px;
    width: 100%;
  }

  .login-wrapper {
    background-color: var(--surface-0);
    color: #393e40;
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    gap: 2rem;
    border-radius: calc(var(--border-radius) * 2);
    border: 1px solid var(--surface-border);
    display: flex;
    flex-direction: column;
    align-items:center;

    form {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .buttons {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items:center;
        justify-content: space-between;
        gap: 1rem;
        margin-top: 2rem;
      }
    }
  }
}

img.logo {
  width: 100%;
  max-width: 450px;
}



input[type="range"] {
  width: 100%;
  outline: none;
}

.center-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-square {
  border: 0;
  width: var(--square-button-px);
  height: var(--square-button-px);
}

.btn-rectangle {
  border: 0;
  width: 6em;
  height: 2em;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.container-wrapper {
  height: calc(100% - var(--square-button-px) - $navbar-height); //footer adapts to square button-px

}

.surface-red {
  @include surface-style(var(--red-400), var(--gray-50));
}

.surface-light-red {
  @include surface-style(var(--red-200), var(--gray-50));
}

.surface-green {
  @include surface-style(var(--green-400), var(--gray-50));
}

.surface-orange {
  @include surface-style(var(--orange-400), var(--gray-50));
}

.surface-grey {
  @include surface-style(var(--surface-400), var(--gray-50));
}

.surface-light-grey {
  @include surface-style(var(--surface-200), var(--gray-50));
}

.surface-blue {
  @include surface-style(var(--blue-400), var(--gray-50));
}

.surface-light-blue {
  @include surface-style(var(--blue-200), var(--gray-50));
}

.surface-purple {
  @include surface-style(var(--purple-400), var(--gray-50));
}


table tbody tr {

  &:has([title="WhatsApp"]) {
    &.ongoing-row {
      background: var(--green-50);
    }
  }

  &:has([title="Messenger"]) {
    &.ongoing-row {
      background: var(--purple-50);
    }
  }

  &.inactive {
    background: var(--surface-200);
  }

  &.warning {
    background: var(--orange-100);
  }

  &.danger {
    background: var(--red-100);
  }
}
///FIXME: supervisor colors
app-mobile-presenter-call div.selected,
app-engagement-transfer-agent-select div.selected,
table tbody tr.selected,
app-supervisor-chat-row.selected
{
  border-left: 4px solid;

  &:not(.ongoing-row):not(.danger):not(.warning):not(.inactive) {
    border-color: var(--primary-500);
    background: var(--surface-200);
  }

  &:has([title="WhatsApp"]) {
    border-color: var(--green-500);

    &.ongoing-row {
      background: var(--green-100);
    }
  }

  &:has([title="Messenger"]) {
    border-color: var(--purple-500);

    &.ongoing-row {
      background: var(--purple-100);
    }
  }

  &.inactive {
    border-color: var(--surface-500);
  }

  &.warning {
    border-color: var(--orange-500);
  }

  &.danger {
    border-color: var(--red-500);
  }
}

/*
table tbody tr.selected,
app-supervisor-chat-row.selected,
div.selected:not(.surface-red) {
  border-left: 4px solid;
}
*/


//Until all tables are converted to primeng tables we will need to update this style to ensure it is compatible with the p-table styles
table.table {
  //color: var(--text-color);
  color: inherit;

  thead {
    background: var(--surface-section);
    user-select: none;

    > tr > th {
      padding: 1rem;
      border-width: 0 0 1px 0;
      border: 1px solid var(--surface-section);
      font-weight: 600;
      background: var(--surface-section);
      transition: box-shadow .2s;
    }
  }

  tbody tr {
    border-bottom: 1px solid var(--surface-border) !important;
  }

  td,
  th {
    vertical-align: middle;
  }
}


//primeng

// when dropdown uses appendTo="body" it is not part of the p-dropdown
// as such this style rule needs to be outside of the p-dropdown component
.p-overlay-content .p-dropdown-panel {
  max-width: var(--sidebar-width-px);
  &.canned-chat-drop-up {
    max-width:calc(var(--sidebar-width-px) - 50px);
    .p-dropdown-item {
      text-overflow: ellipsis;
    }
  }
}

.p-dropdown {
  width: 100%;
}

.p-dropdown:not(.p-disabled) {
  background: var(--primary-color);
  color: var(--primary-color-text);


  .p-dropdown-label,
  .p-dropdown-trigger,
  .p-dropdown-label.p-placeholder
  {
    color: inherit;
  }

  .p-dropdown-panel {

    .p-dropdown-items {

      .p-dropdown-item {
        &:not(.p-disabled):hover,
        &.p-highlight {
          border-left: 6px solid var(--primary-color);
        }
      }

      //.p-dropdown-item-group {
      //  background: var(--primary-color);
      //  color: var(--primary-color-text);
      //}
    }
  }
}


.p-tabview .p-tabview-panels {
  padding: 0;
}

.nav-tabs .nav-link,
.p-tabview .p-tabview-nav li a.p-tabview-nav-link,
.p-tabmenu .p-tabmenu-nav {
  height: $tab-button-height;
  line-height: $tab-button-height;
}

.p-tabview-panel {
  height: 100%;
}

p-tabview, .p-tabview {
  height: 100%;
}

.p-tabview .p-tabview-panels {
  height: calc(100% - $tab-button-height);
  padding: 0;
}

.p-panel {
  border: none;
}

.p-panel .p-panel-header {
  border: none;
  background: none;
  font-weight: 600;
  padding-left: 0.5em;
  padding-right: 0.25em;
}

.p-panel .p-panel-content {
  padding: 0;
  border: none;
}

////end Primeng


.sidebar-panel {
  background: var(--surface-section);
  overflow: hidden;
  width: var(--sidebar-width-px);
  min-width: var(--sidebar-width-px);
  border-radius: calc(var(--border-radius) * 2);
  padding: var(--content-padding);
  border: 1px solid var(--surface-border);

  &, &-max {
    max-width: var(--sidebar-width-px);
  }

  &-inner-border-radius {
    border-radius: var(--border-radius);
  }
}

.main-layout {
  background: var(--surface-section);
  border-radius: calc(var(--border-radius) * 2);
  padding: var(--content-padding);
  border: 1px solid var(--surface-border);
}

.main-layout-section {
  background: var(--surface-section);
}

.main-layout-card {
  background: var(--surface-card);

}

.primary-color-text {
  color: var(--primary-color);
}


.justified {
  ul.p-tabview-nav {
    li:not(.p-hidden) {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  li:not(.p-hidden).p-tabmenuitem {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .p-menuitem-link {
      padding: 2px;
      border: none;
    }
  }
}

//Needs to be here so that we are not duplicating ourselves
//Electron video needs it
.mainVideoContainer > video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.peersContainer > video {
  width: 25%;
  object-fit: cover;
  cursor: pointer;
  pointer-events: all;
  height: 100%;
  float:right
}

.btn-bottom-control {
  width: 50px;
  height: var(--square-button-px);
}

.bottom-ctrls {
  fa-icon {
    font-size: 1.75rem;
  }

  .p-divider.p-divider-vertical {
    margin: 0 0.5rem;
    padding: 0.5rem 0;
  }
}

.row-height {
  height: 50px;
}

.overlay-content {
  max-height: 300px;
  overflow-y: auto;
}
